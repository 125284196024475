import React, { useState, useEffect, useRef } from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { Calendar, CheckCircle, Circle, Menu } from 'lucide-react'

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-[#0f2137] z-50`}>
    <div className="flex flex-col items-center py-4 space-y-4">
      <NavItem href="/">Home</NavItem>
      <NavItem href="/learn-more">Learn More</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/announcements">Announcements</NavItem>
      <NavItem href="/roadmap">Roadmap</NavItem>
    </div>
  </div>
);

const NechepsoText = ({ className }) => (
  <span className={`text-white ${className}`}>Nechepso</span>
);

const NavItem = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-sm uppercase tracking-wider hover:text-[#6a9ad4] transition-colors"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
)

const NechepsoLogo = () => (
  <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
    <span className="font-outhill text-white">NechepsO</span>
  </h1>
);

const TimelineItem = ({ date, title, description, completed, isLast }) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const controls = useAnimation()

  useEffect(() => {
    if (isInView) {
      controls.start("visible")
    }
  }, [isInView, controls])

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
      }}
      transition={{ duration: 0.5 }}
      className="flex mb-8 relative"
    >
      <div className="flex-shrink-0 w-32 text-right pr-8 py-3">
        <div className="flex items-center justify-end mb-2">
          <Calendar className="w-4 h-4 mr-2 text-[#c5d8ed]" />
          <span className="text-sm text-[#c5d8ed]">{date}</span>
        </div>
      </div>
      <div className="flex-shrink-0 relative">
        <div className={`w-6 h-6 rounded-full ${completed ? 'bg-[#6a9ad4]' : 'bg-[#535f8d] border-2 border-[#6a9ad4]'} flex items-center justify-center z-10 relative`}>
          {completed ? (
            <CheckCircle className="w-4 h-4 text-[#280137]" />
          ) : (
            <Circle className="w-4 h-4 text-[#6a9ad4]" />
          )}
        </div>
        {!isLast && (
          <div className="absolute top-6 bottom-0 left-1/2 w-0.5 bg-[#6a9ad4] -translate-x-1/2"></div>
        )}
      </div>
      <div className="flex-grow pl-8 py-3">
        <h3 className="text-xl font-bold text-[#c5d8ed] mb-2">
          {typeof title === 'string' ? title : title}
        </h3>
        <p className="text-[#e5eef7]">
          {typeof description === 'string' ? description : description}
        </p>
      </div>
    </motion.div>
  )
}

const TimelinePage = () => {
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY)
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const parallaxRef = useRef(null)

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollPosition = window.pageYOffset
      const parallaxElements = parallaxRef.current.querySelectorAll('.parallax')
      parallaxElements.forEach((el) => {
        const speed = el.dataset.speed
        const yPos = -(scrollPosition * speed)
        el.style.transform = `translateY(${yPos}px)`
      })
    }
    window.addEventListener('scroll', parallaxEffect)
    return () => window.removeEventListener('scroll', parallaxEffect)
  }, [])

  const timelineItems = [   
    {
      date: "May 22nd-26th, 2025",
      title: "Nechepso at NORWAC 2025",
      description: "Catch us at NORWAC 2025 in Tukwila, WA! We are vendors and will be showcasing Third House and Nechepso to the public.",
      completed: false
    },
    {
      date: "May 22nd-26th, 2025",
      title: "Patrick Watson to speak at NORWAC",
      description: "Patrick Watson will be speaking at the NORWAC conference in Tukwila, Washington. He will be speaking about Babylonian Goal Years and Planetary Activations.",
      completed: false
    },
    {
      date: "May 2025",
      title: "Nechepso 1.3 - The Visual & Financial Modules",
      description: "The final major update to complete the Nechepso suite. Still, this is just the beginning of what we have planned.",
      completed: false
    },
    {
      date: "April 2025",
      title: "Nechepso Mobile & Third House Mobile",
      description: "The second major update we are planning for Nechepso is the Research module. This major update to allow for more advanced research and analysis of astrological data.",
      completed: false
    },
    {
      date: "March 2025",
      title: "Nechepso 1.2 - Research Module Update",
      description: "The second major update we are planning for Nechepso is the Research module. This major update to allow for more advanced research and analysis of astrological data.",
      completed: false
    },
    {
      date: "March 7th-9th, 2025",
      title: "Convergence 2025 in Orlando, FL",
      description: "Catch the TMT team at Convergence 2025 in Orlando, FL! We will be present as a vendor showcasing Third House and Nechepso.",
      completed: false
    },
    {
      date: "Jan-Feb 2025",
      title: "Nechepso 1.1 - AI Module Update",
      description: "The first major update we are planning for Nechepso is the AI module. Beta Testers will be the first to get their hands on it.",
      completed: false
    },
    {
      date: "December 2024",
      title: "Targeted Date For Major Third House Update",
      description: "The team is looking to do a full update on Third House, including the UI, new features, and much more.",
      completed: false
    },
    {
      date: "Q4 2024",
      title: "Nechepso Beta Testing & Release",
      description: "Beta testing will take place in Q4 2024. The initial release of Nechepso will be limited to the Workspace, Personal, and Resource Modules.",
      completed: false
    },
    {
      date: <span className="line-through text-xs">November 2024</span>,
      title: <span className="line-through text-xs">Targeted Release Date for Nechepso 1.0</span>,
      description: <span className="line-through text-xs">The initial release of Nechepso will be limited to the Workspace, Personal, and Resource Modules.</span>,
      completed: false
    },
    {
      date: <span className="line-through text-xs">October 17th-31st, 2024</span>,
      title: <span className="line-through text-xs">Beta Testing</span>,
      description: <span className="line-through text-xs">Beta testing is slated to take place October 2024.</span>,
      completed: false
    },
    {
      date: "October 7th, 2024",
      title: "Celestial Compass Interview",
      description: "Catch the team on the Celestial Compass podcast talking about the future with Nechepso and Third House.",
      completed: true
    },
    {
      date: "September 15th, 2024",
      title: "Private Live Stream For Customers",
      description: "The team hosts a private live stream for the customers who purchased the presale to give them the first glimpse of the software, what it will do, and what to expect next.",
      completed: true
    },
    {
      date: "August 17th, 2024",
      title: "Beta Testing Sign Ups Begin",
      description: "The team begins sending out the beta testing invites to the people who signed up during the presale.",
      completed: true
    },
    {
      date: "June 14th, 2024",
      title: "Third House Launch",
      description: "The official launch of Third House, the site is live for testing and feedback from the public.",
      completed: true
    },
    {
      date: "June 5th, 2024",
      title: "Introduction to Third House",
      description: "The team officially announced Third House, a new astrology social network built to connect the astrological community.",
      completed: true
    },
    {
      date: "May 17th, 2024",
      title: "Live Q&A Survey Follow Up",
      description: "The team held a live Q&A survey to get feedback from the community on what they liked and what they didn't like about Nechepso.",
      completed: true
    },
    {
      date: "May 7th, 2024",
      title: "Introduction of Nechepso & AI Demo",
      description: "We revealed to the world what it was we were working on, an AI-powered astrological software called Nechepso. We also did a live demo showing the AI in action.",
      completed: true
    },
    {
      date: "May 2nd, 2024",
      title: "BarefootJoey Joins The Team",
      description: "Knowing there was a lot of programming ahead, the team brought in another developer as a partner to make sure developing goes smoothly.",
      completed: true
    },
    {
      date: "April 19th, 2024",
      title: "Initial AI Prototype Complete",
      description: "The first iteration of the Nechepso AI trained on Traditional Astrology, History, and Astrological Ethics is completed and ready for demo.",
      completed: true
    },
    {
      date: "January 18th, 2024",
      title: "Thema Mundi Technologies is Born",
      description: "John, Patrick, and Nick filed to create their new company called Thema Mundi Technologies, for all of their future projects.",
      completed: true
    },
    {
      date: "January 5th, 2024",
      title: "Initial Third House Prototype Complete.",
      description: "The very first iteration of Third House is completed and uploaded to the internet for internal testing.",
      completed: true
    },
    {
      date: "December 21st, 2023",
      title: "It's Made Official",
      description: "John and Patrick decide to make Nechepso a reality, it's here Patrick suggests introducing Nick to the project where he joined the following week.",
      completed: true
    },
    {
      date: "December 3rd, 2023",
      title: "Inception of Nechepso & Third House",
      description: "The idea for Nechepso is born. John Streaker reaches out to Patrick Watson to pitch the idea of a new astrology software to him and that's where the journey begins.",
      completed: true
    }
  ]

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0f2137] via-[#3776c1] to-[#a3c1e4] text-white overflow-hidden">

      {/* Parallax stars */}
      <div ref={parallaxRef} className="absolute inset-0 overflow-hidden">
        {[...Array(100)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute rounded-full bg-white parallax"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: Math.random() * 4 + 1,
              height: Math.random() * 4 + 1,
            }}
            data-speed={Math.random() * 0.5 + 0.2}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.7, 1, 0.7],
            }}
            transition={{
              repeat: Infinity,
              duration: Math.random() * 3 + 2,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>

      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300`}>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-90' : 'opacity-0'}`}>
          <div className="absolute inset-0 bg-gradient-to-r from-[#a3c1e4] via-[#3776c1] to-[#0f2137]"></div>
        </div>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="absolute inset-0 backdrop-blur-md"></div>
        </div>
        <div className="container mx-auto px-4 py-6 flex justify-between items-center relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <NechepsoLogo />
          </motion.div>
          <div className="hidden md:flex space-x-8">
            <NavItem href="/">Home</NavItem>
            <NavItem href="/learn-more">Learn More</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
            <NavItem href="/announcements">Announcements</NavItem>
            <NavItem href="/roadmap">Roadmap</NavItem>
          </div>
          <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>
        <MobileMenu isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>

      <main className="container mx-auto px-4 pt-40 pb-16">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-5xl md:text-7xl font-bold text-center mb-12 text-[#c5d8ed]"
        >
          The Journey and<br />
          the Destination
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-2xl text-center mb-12 max-w-3xl mx-auto text-[#e5eef7]"
        >
          Follow the journey of <NechepsoText className="font-bold" /> from conception to launch
        </motion.p>

        <motion.p
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-xl text-center mb-12 max-w-xl mx-auto text-[#e5eef7]"
        >
          All dates are projections and subject to change. We are aiming to complete this roadmap quicker than expected.
        </motion.p>

        <div className="max-w-4xl mx-auto">
          {timelineItems.map((item, index) => (
            <TimelineItem
              key={index}
              date={item.date}
              title={item.title}
              description={item.description}
              completed={item.completed}
              isLast={index === timelineItems.length - 1}
            />
          ))}
        </div>
      </main>

      <footer className="bg-[#2a5688] py-8 relative">
        <div className="container mx-auto px-4 text-center relative z-10">
          <p className="text-[#c5d8ed]">&copy; 2024 Thema Mundi Technologies. All rights reserved.</p>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-8 bg-repeat-x" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23c5d8ed' points='0,100 50,0 100,100'/%3E%3C/svg%3E")`,
          backgroundSize: '50px 100%',
          opacity: 0.2,
        }}></div>
      </footer>
    </div>
  )
}

export default TimelinePage
