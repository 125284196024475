import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Menu, ChevronDown, Play } from 'lucide-react';

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-[#0f2137] z-50`}>
    <div className="flex flex-col items-center py-4 space-y-4">
      <NavItem href="/">Home</NavItem>
      <NavItem href="/learn-more">Learn More</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/announcements">Announcements</NavItem>
      <NavItem href="/roadmap">Roadmap</NavItem>
    </div>
  </div>
);

const NechepsoText = ({ className }) => (
  <span className={`text-white ${className}`}>Nechepso</span>
);

const NavItem = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-sm uppercase tracking-wider hover:text-[#6a9ad4] transition-colors"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const NechepsoLogo = () => (
  <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
    <span className="font-outhill text-white">NechepsO</span>
  </h1>
);

const Button = ({ variant, size, className, onClick, children }) => (
  <button
    className={`${className} ${
      variant === 'secondary' ? 'bg-white text-[#280137]' : 'bg-[#6a9ad4] text-white'
    } ${size === 'icon' ? 'p-2' : 'px-4 py-2'} rounded`}
    onClick={onClick}
  >
    {children}
  </button>
);

const Card = ({ className, children }) => (
  <div className={`bg-white rounded-lg shadow-md overflow-hidden ${className}`}>
    {children}
  </div>
);

const CardContent = ({ className, children }) => (
  <div className={`p-6 ${className}`}>{children}</div>
);

const Image = ({ src, alt, width, height, className }) => (
  <img src={src} alt={alt} width={width} height={height} className={className} />
);

const VideoCard = ({ title, videoId }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
      }}
      transition={{ duration: 0.5 }}
    >
      <div className="overflow-hidden rounded-lg">
        <div className="relative aspect-video">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <h3 className="mt-2 text-lg font-semibold text-[#c5d8ed]">{title}</h3>
    </motion.div>
  );
};

const OwnerCard = ({ name, role, bio, contact, website, image }) => {
  return (
    <Card className="bg-gradient-to-br from-[#a3c1e4] to-[#3b79be] bg-opacity-30 backdrop-blur-lg rounded-lg p-6 shadow-lg transform hover:scale-105 transition-all duration-300">
      <CardContent className="flex flex-col md:flex-row items-center">
        <Image
          src={image}
          alt={name}
          width={200}
          height={200}
          className="rounded-full mb-4 md:mb-0 md:mr-6"
        />
        <div>
          <h3 className="text-2xl font-bold text-[#1f436e] mb-2">{name}</h3>
          <p className="text-[#27548a] mb-4">{role}</p>
          <p className="text-[#e5eef7] mb-2">{bio}</p>
          {contact && (
            <p className="text-[#c5d8ed]">
              <a 
                href={contact.props.href} 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-[#c5d8ed] hover:text-[#173253] transition-colors duration-300"
              >
                {contact.props.children}
              </a>
            </p>
          )}
          {website && (
            <p className="text-[#c5d8ed]">
              <a 
                href={website.props.href} 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-[#c5d8ed] hover:text-[#173253] transition-colors duration-300"
              >
                {website.props.children}
              </a>
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const AccordionItem = ({ value, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-[#27548a]">
      <button
        className="w-full py-4 px-6 text-left text-[#27548a] flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-[#27548a] font-semibold">{title}</span>
        <ChevronDown className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <div className="py-4 px-6 text-[#173253]">
          {content}
        </div>
      )}
    </div>
  );
};

const LearnMorePage = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollPosition = window.pageYOffset;
      const parallaxElements = parallaxRef.current.querySelectorAll('.parallax');
      parallaxElements.forEach((el) => {
        const speed = el.dataset.speed;
        const yPos = -(scrollPosition * speed);
        el.style.transform = `translateY(${yPos}px)`;
      });
    };
    window.addEventListener('scroll', parallaxEffect);
    return () => window.removeEventListener('scroll', parallaxEffect);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0f2137] via-[#3776c1] to-[#a3c1e4] text-white overflow-hidden">
      {/* Egyptian-inspired background pattern */}
      

      {/* Parallax stars */}
      <div ref={parallaxRef} className="absolute inset-0 overflow-hidden">
        {[...Array(100)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute rounded-full bg-white parallax"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: Math.random() * 4 + 1,
              height: Math.random() * 4 + 1,
            }}
            data-speed={Math.random() * 0.5 + 0.2}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.7, 1, 0.7],
            }}
            transition={{
              repeat: Infinity,
              duration: Math.random() * 3 + 2,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>

      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300`}>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-90' : 'opacity-0'}`}>
          <div className="absolute inset-0 bg-gradient-to-r from-[#a3c1e4] via-[#3776c1] to-[#0f2137]"></div>
        </div>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="absolute inset-0 backdrop-blur-md"></div>
        </div>
        <div className="container mx-auto px-4 py-6 flex justify-between items-center relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <NechepsoLogo />
          </motion.div>
          <div className="hidden md:flex space-x-8">
            <NavItem href="/">Home</NavItem>
            <NavItem href="/learn-more">Learn More</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
            <NavItem href="/announcements">Announcements</NavItem>
            <NavItem href="/roadmap">Roadmap</NavItem>
          </div>
          <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>
        <MobileMenu isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>
      
      <main className="container mx-auto px-4 pt-40 pb-16">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-5xl md:text-7xl font-bold text-center mb-12 text-[#c5d8ed]"
        >
          Learn More About <NechepsoText className="font-bold" />
        </motion.h1>

        <section className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-8 text-white">Featured Videos</h2>
          <div className="grid md:grid-cols-2 text-center text-white lg:grid-cols-3 gap-8">
            <VideoCard
              title="Introduction to Nechepso and AI Demo"
              videoId="kXucJu8I-SQ"
            />
            <VideoCard
              title="Nechepso Live Q & A Survey Follow Up"
              videoId="P-SzChNtcOk"
            />
            <VideoCard
              title="Introducing, Third House Social"
              videoId="1qrteGHaq1E"
            />
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center text-white">Meet the Founders</h2>
          <div className="space-y-8">
            <OwnerCard
              name="John Streaker"
              role="Developer"
              bio="John Streaker is a self-taught programmer and AI developer with expertise across various fields. As a private student of the traditional school of astrology, he has been able to merge his passion for coding and astrology together. His award-winning programming is now dedicated to building Nechepso, a platform aimed at fulfilling the dreams of astrologers everywhere."
              contact={<a href="https://x.com/JohnStreaker" target="_blank" rel="noopener noreferrer">X: @JohnStreaker</a>}
              image="/images/john.png"
            />
            <OwnerCard
              name="Patrick Watson"
              role="Astrologer"
              bio="Patrick is a professional astrologer, having begun his studies 2 decades ago. In combining modern and ancient astrology, the software tools he needed to explore Hellenistic techniques did not yet exist, so he made them. In order to go further, he needs Nechepso."
              contact={<a href="https://x.com/pwatsonastro" target="_blank" rel="noopener noreferrer">X: @pwatsonastro</a>}
              website={<a href="https://patrickwatsonastrology.com/" target="_blank" rel="noopener noreferrer">patrickwatsonastrology.com</a>}
              image="/images/patrick.png"
            />
            <OwnerCard
              name="Nick Dagan Best"
              role="Astrologer"
              contact={<a href="https://x.com/NickDaganBest" target="_blank" rel="noopener noreferrer">X: @NickDaganBest</a>}
              website={<a href="https://nickdaganbestastrologer.com" target="_blank" rel="noopener noreferrer">nickdaganbestastrologer.com</a>}
              bio="Nick Dagan Best, also known as the Human Ephemeris, is a professional astrologer of 30 years. His studies of world histories and biographies led him to pioneer neo-Babylonian astrology and ask questions that may only be able to be answered by Nechepso."
              image="/images/nick.jpg"
            />
            <OwnerCard
              name="BarefootJoey"
              role="Developer"
              contact={<a href="https://x.com/BarefootJoeyTV" target="_blank" rel="noopener noreferrer">X: @BarefootJoeyTV</a>}
              website={<a href="https://barefootjoey.com/" target="_blank" rel="noopener noreferrer">barefootjoey.com</a>}
              bio="BarefootJoey is a passionate developer who uses his award-winning programming expertise to develop open-source tools for the financial and astrological communities. With a focus on innovation, he continually seeks to empower users by creating accessible and high-performance software solutions."
              image="/images/joey.png"
            />
          </div>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-8 text-[#27548a]">FAQ: Frequently Asked Questions</h2>
          <div className="w-full">
            <AccordionItem
              value="item-1"
              title="Will Nechepso be accurate in its calculations and interpretations?"
              content="Yes. Our calculations will be powered by the renowned Swiss Ephemeris and the most complete and accurate atlas and timezone databases available. As for the AI-generated interpretations, the quality, structure and training methods of our data are all being developed with accuracy at the highest level as our primary goal. The interpretations themselves will still need to be filtered by an astrologer to be of proper use to a client. Keep in mind that the software stands on its own even without the AI component."
            />
            <AccordionItem
              value="item-2"
              title="Will my data be private and secure with Nechepso?"
              content="Yes. One of the ways we are accomplishing this is by including the LLM as part of the software package which will be locally stored on your computer. We will not have access to, nor will we request any personal or sensitive information that may be shared with Nechepso or the Journal which will be stored locally. Internet access will only be required for making software updates or for updating your chart database from a mobile device. We will continue finding ways to make Nechepso secure."
            />
            <AccordionItem
              value="item-3"
              title="Where is the data for the AI component coming from? Isn't AI just theft?"
              content="The data is coming from astrologers and no, AI does not require theft of intellectual property. We value high quality data over quantity of data, and this is how it is possible for us to both create a powerful AI from a limited dataset and to do so ethically. We have a firm commitment to honoring the intellectual property of astrologers in crafting our datasets. All data up to this point comes from the combined astrological knowledge, expertise and experience of astrologers Patrick Watson (CAP, NCGR-I), Nick Dagan Best (NCGR-III) and John Streaker. It has all been written out by hand. Any sources we consult come from the public domain. No one's copyrighted works are being used to create the data. We have sought and received permission from some authors to mention them - but even then we haven't quoted their works directly, we've simply given them credit that they deserve for their great ideas. In the future, we plan on having more astrologers join our team and we will be able to apply for licenses to copyrighted works from their authors and/or copyright holders to help us improve and enlarge our dataset. As a sign of our commitment to ethically compiled datasets, we have even requested ISAR develop an astrological AI benchmark to include provisions for protections of intellectual copyright."
            />
            <AccordionItem
              value="item-4"
              title="Is this software going to replace the job of an astrologer?"
              content="No. We understand the concern, but Nechepso can only be an assistant for an astrologer, not a replacement for one. There are certain questions that it would be unethical or irresponsible for an AI to try to answer without human judgment, so there will still be an ongoing need for astrologers to address complex and nuanced cases. The technical application of the AI in the software is meant to augment the user's experience, not define it. Thinking this software can replace an astrologer is like thinking you can safely fly a plane without any training just because the plane has an autopilot system. We still need pilots, and we still need astrologers."
            />
            <AccordionItem
              value="item-5"
              title="What are the minimum requirements for running Nechepso?"
              content={
                <>
                  System Requirements will be tiered depending on your system. Minimum requirements potentially subject to change.<br /><br />

                  Operating System:<br />
                  • Windows: Windows 10 or later<br />
                  • macOS: macOS Catalina (10.15) or later<br />
                  • Linux: Ubuntu 20.04 LTS or similar distributions<br /><br />

                  Processor:<br />
                  • Minimum: Intel Core i3, i5, or equivalent<br /><br />

                  Currently, we are looking to have three tiers for Non-AI, Lite AI, and Standard AI.<br /><br />

                  Non-AI:<br />
                  • RAM: 4 GB<br />
                  • Storage: 8 GB<br /><br />

                  Lite AI:<br />
                  • RAM: 8 GB<br />
                  • Storage: 12 GB<br /><br />

                  Standard AI:<br />
                  • RAM: 12 GB<br />
                  • Storage: 15 GB<br /><br />

                  Graphics:<br />
                  • Integrated graphics with support for OpenGL 2.0 or later for basic visualizations will work.<br />
                  • The presence of any GPU will significantly improve performance.
                </>
              }
            />
          </div>
        </section>
      </main>

      <footer className="bg-[#2a5688] py-8 relative">
        <div className="container mx-auto px-4 text-center relative z-10">
          <p className="text-[#c5d8ed]">&copy; 2024 Thema Mundi Technologies. All rights reserved.</p>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-8 bg-repeat-x" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23c5d8ed' points='0,100 50,0 100,100'/%3E%3C/svg%3E")`,
          backgroundSize: '50px 100%',
          opacity: 0.2,
        }}></div>
      </footer>
    </div>
  );
};

export default LearnMorePage;